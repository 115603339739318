<!-- 告知书管理  By阿旭 -->
<template>
  <div class="main">
    <TableModel
      :is-show-select="false"
      :loading="loading"
      :table-data="noticeBook"
      table-size="medium">
      <el-table-column prop="noticeBookName" label="告知书名称">
        <template slot-scope="scope">
          <el-link type="primary" @click="seeFun(scope.row)">{{scope.row.noticeBookName}}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="noticeBookType" label="告知书类型"></el-table-column>
      <el-table-column prop="crtUserName" label="上传用户"></el-table-column>
      <!--<el-table-column label="操作">-->
      <!--  <template slot-scope="scope">-->
      <!--    <el-link type="primary" @click="editNoticeBookInfo(scope.row.id)">修改</el-link>-->
      <!--    <el-link type="primary" class="tabel-btn" @click="delNoticeBookInfo(scope.row.id)">删除</el-link>-->
      <!--  </template>-->
      <!--</el-table-column>-->
    </TableModel>

    <div class="content_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNumber"
          :page-size="pageSize"
          :total="total"
          :page-sizes="[10, 20, 40, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import TableModel from '@/components/TableModel';
import { noticeBookTemplate, updateNoticeBook, deleteNoticeBook,findIsolationNotice } from '../../api/RuleManagement/crowdAnalysis';
import { downloadExportFile,importFile, messageBox } from "../../utils/utils";
import constants from "../../common/constants";

export default {
  components: { TableModel },
  data() {
    return {
      loading: false,
      noticeBook: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
    };
  },

  created() {
    this.getNoticeBookTemplate()
  },

  methods: {
    seeFun(row){
      console.log(111,row);
      let params = {id:row.id,
        // hospitalId: this.userdoctor.hospitalId,
        // orgCode: this.userdoctor.orgCode
        }
      findIsolationNotice({ params }).then(res => {
        console.log(11111111111,res);
        downloadExportFile(res.data, row.noticeBookName);
      })
    },
    // 告知书管理列表
    getNoticeBookTemplate() {
      this.loading = true;
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };
      noticeBookTemplate({ params }).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          let { totalRow, list } = data;
          list.forEach(item => {
            constants.INSULATE_TYPE.forEach(key => {
              if (item.type == key.value) {
                item.noticeBookType = key.label
              }
            })
          })
          this.noticeBook = list;
          this.total = totalRow;
        } else {
          this.$message.error(msg);
        }
        this.loading = false;
      })
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getNoticeBookTemplate();
    },

    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getNoticeBookTemplate();
    },

    // 修改数据
    editNoticeBookInfo(id) {
      updateNoticeBook({ id }).then(res => {

      })
    },

    // 删除数据
    delNoticeBookInfo(id) {
      deleteNoticeBook({ id }).then(res => {

      })
    }
  }
};
</script>

<style lang='scss' scoped>
.content_page {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.tabel-btn{
  margin-left: 10px;
}
</style>
